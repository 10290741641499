import React from "react";
import "./FailPage.css";
import { getTranslation } from "./Utility";
const FailPage = ({ onResetFail, selectedLanguage }) => {
  return (
    <div className="main">
      <div className="content">
        <div style={{ width: "920px", padding: "10px", margin: "0 auto" }}>
          <p style={{ textAlign: "center" }}>
            <img
              src="./error.jpg"
              style={{ maxWidth: "90%" }}
              alt="Error illustration"
            />
          </p>
          <p
            style={{
              color: "#fff",
              fontSize: "18px",
              fontWeight: "bold",
              textAlign: "center",
              border: "1px solid #ddd",
              padding: "20px 0",
            }}
          >
            {getTranslation(selectedLanguage, "err_account")}
            <br />
            <br />
            <button
              onClick={onResetFail}
              style={{
                background: "none",
                border: "none",
                color: "red",
                textDecoration: "underline",
                cursor: "pointer",
                padding: 0,
                font: "inherit",
              }}
            >
              {getTranslation(selectedLanguage, "main")}
            </button>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FailPage;
